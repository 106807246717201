/** 
* Project: Glass house
* Author: Jeremy Chen
* Start Date: 18 July 18
*/

@import 'default';

$primaryColor: #851e2d !default;
$white: white !default;
$black: black !default;

@include font('C', 'colonnamt');
@include font('GR', 'gothic-1');
@include font('GBold', 'gothicb0-1');
@include font('GBoldItalic', 'gothicbi');
@include font('GItalic', 'gothici');


// mixin
@mixin title($color: $primaryColor) {
    font-size: 38px !important;
    line-height: 1.2;
    color: $color;
    @include media(800px) {
        font-size: 28px !important;
    }
    @include media(420px) {
        font-size: 22px !important;
    }
}

// grid 
.g-contact {
    padding: 10% 0 !important;

    .title {
        margin: {
            top: 40px;
        }
        @include title();
        @include media(420px) {
            margin: {
                top: 0;
            }
        }
    }

    .note {
        margin: {
            top: 30px;
        }
        color: $primaryColor;
    }

    .wpcf7 {
        max-width: 900px;
        margin: 30px auto 0;

        p {
            font-size: 0;
        }

        %input {
            margin: {
                top: 10px;
            }
            background-color: transparent;
            border: {
                color: $primaryColor;
                radius: 6px;
            }
            font-size: 18px;
            color: $primaryColor;
            @include media(420px) {
                font-size: 14px;
            }

            &:focus {
                outline: 0;
            }

            &::placeholder {
                color: $primaryColor;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                color: $primaryColor;
            }

            &:-ms-input-placeholder {
                color: $primaryColor;
            }
        }

        input {
            &[type="text"],
            &[type="tel"],
            &[type="email"] {
                width: (100% / 3 - 1);
                @extend %input;
                @include media(420px) {
                    width: 100%;
                    height: 34px;
                }
            }

            &[type="tel"],
            &[type="email"] {
                margin: {
                    left: (3% / 2);
                }
                @include media(420px) {
                    margin: {
                        left: 0;
                    }
                }
            }

            &[type="submit"],
            &[type="reset"] {
                padding: 4px 15px;
                border-radius: 7px;
                font: 14px "GR";
            }

            &[type="reset"] {
                &:hover {
                    border: {
                        color: transparent;
                    }
                }
            }
        }

        textarea {
            @extend %input;
            resize: none;
        }

        .btn-wrap {
            margin: {
                top: 20px;
            }
            text-align: center;
        }
    }
}
.g-product {
    padding: 14% 0 6% !important;
    @extend .f-bevel;
    background: url('../images/contact-bg.jpg') center bottom/100% auto no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 5%;
        right: 20%;
        width: 110px;
        height: 100px;
        background: url('../images/adorn3.png') center center/contain no-repeat;
        @include media(420px) {
            top: 2%;
            width: 80px;
            height: 70px;
        }
        @include media(400px) {
            top: 20px;
            right: 10%;
        }
    }

    .content-wrapper {
        background-color: transparent;
    }

    #secondary,
    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-ordering {
        @extend %none;
    }

    #primary {
        float: none;
        width: 100%;
    }

    .woocommerce-products-header {
        padding: 20px 0 30px;
        text-align: center;

        .archive-title {
            @include title();
        }
    }
}


// Module
.m-link {
    font-size: 0;
    
    > a {
        display: inline-block;
        width: 30px;
        height: 30px;
        font-size: 0;
        text-indent: -999px;

        @for $p from 1 through 2 {
            &:nth-child(#{$p}) {
                @if $p == 1 {
                    background: url('../images/icon-facebook.png') center center/contain no-repeat;

                } @else {
                    margin: {
                        left: 8px;
                    }
                    background: url('../images/icon-instagram.png') center center/contain no-repeat;
                }
            }
        }
    }
}
.m-title {
    @include title();
    &.s-white {
        color: $white;
    }

    strong {
        font-family: "GBold";
        font-weight: normal;
    }

    em {
        font-family: "GR";
        font-style: normal;

        &:before {
            content: '';
            display: inline-block;
            width: 45px;
            height: 45px;
            margin: {
                left: 10px;
            }
            background: url('../images/icon.png') center center/contain no-repeat;
            vertical-align: text-bottom;
            @include media(800px) {
                width: 30px;
                height: 30px;
            }
        }
    }
}


// Function
.f-bevel {
    position: relative;
    margin: {
        top: -200px;
    }
    clip-path: polygon(0 180px, 100% 0, 100% 100%, 0 100%);
    background-color: $white;
    @include media(800px) {
        margin: {
            top: -110px;
        }
        clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 100%);
    }
    @include media(420px) {
        margin: {
            top: -40px;
        }
        clip-path: polygon(0 40px, 100% 0, 100% 100%, 0 100%);
    }
    @include media(400px) {
        margin: {
            top: 0;
        }
        clip-path: none;
    }
}
.f-bevel2 {
    position: relative;
    margin: {
        top: -200px;
    }
    clip-path: polygon(0 0, 100% 180px, 100% 100%, 0 100%);
    background-color: $white;
    @include media(800px) {
        margin: {
            top: -110px;
        }
        clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
    }
    @include media(420px) {
        margin: {
            top: -40px;
        }
        clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 100%);
    }
    @include media(400px) {
        margin: {
            top: 0;
        }
        clip-path: none;
    }
}



// head and foot
.header-clone {
    @extend %none;
}
.site-header {
    position: absolute !important;
    padding: 0;
    background-color: transparent !important;
    @include media(1024px) {
        &.fixed {
            position: absolute !important;
        }
    }

    &:after {
        $w: 73%;

        content: '';
        position: absolute;
        top: 65%;
        right: 0;
        transform: translateY(-50%);
        max-width: 1280px;
        width: $w;
        height: 40px;
        background-color: $primaryColor;
        @include media(1024px) {
            width: 10%;
            min-width: 100px;
        }
        @include media(420px) {
            display: none;
        }
    }

    .site-logo {
        max-height: 74px;
    }

    .header-wrap {
        position: relative;
        z-index: 2;

        .container > .row {
            flex-wrap: wrap;
        }
    }

    .search-wrap {
        width: 100%;
        padding: 15px 0 0;
        margin: {
            bottom: -10px;
        }
        @include media(1024px) {
            overflow: hidden;
            padding: {
                bottom: 5px;
            }
        }

        .search {
            float: right;
            position: relative;
            margin: {
                right: 58px;
            }
            @include media(1024px) {
                margin: {
                    right: 18px;
                }
            }
        }

        .search-text {
            width: 160px;
            height: 25px;
            padding: 0 10px !important;
            background-color: transparent;
            border-radius: 20px;
            font-size: 12px;
            color: $white;

            &::placeholder {
                color: $white;
                opacity: 1;
                text-transform: capitalize;
            }
            
            &:-ms-input-placeholder {
                color: $white;
                text-transform: capitalize;
            }
            
            &::-ms-input-placeholder {
                color: $white;
                text-transform: capitalize;
            }

            &:focus {
                outline: 0;
            }
        }

        .btn {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            padding: 0;
            background: url('../images/icon-search.png') center center/contain no-repeat;
            background-color: transparent;
            border: none;
            font-size: 0;
            color: transparent;
        }
    }

    .menu-item {
        padding: 0 45px !important;
        text-transform: uppercase;
        @include media(800px) {
            padding: 0 !important;
        }

        > a {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-5px, -50%);
                width: 1px;
                height: 14px;
                background-color: $white;
                @include opacity(0);
                @include media(800px) {
                    transform: translate(40px, -50%);
                }
            }
            &:hover {
                &:before {
                    @include opacity(1);
                    transition: opacity .2s linear;
                }
            }
        }

        &.current-menu-item,
        &.current_page_item {
            > a {
                font-family: "GBold" !important;

                &:before {
                    @include opacity(1);
                }
            }
        }
    }

    @include media(1024px) {
        .btn-menu {
            margin: {
                top: 16px;
            }
        }

        #mainnav-mobi {
            top: 48px;
            background-color: $primaryColor;

            .menu-item {
                border: {
                    top-color: rgba($white, .5);
                }

                &:first-child {
                    border: {
                        top: none;
                    }
                }
            }
        }
    }
}
.footer-widgets {
    position: relative;
    padding: 40px 0 30px;
    background: url('../images/foot-bg.jpg') center center/cover no-repeat;
    color: $white;
    &.s-bg {
        background-image: none;
        background-color: #801517;
    }

    .title {
        margin: {
            bottom: 45px;
        }
        color: $white;
        font: 22px "GBold";
        @include media(800px) {
            margin: {
                bottom: 20px;
            }
        }
    }

    .sidebar-column {
        margin: {
            bottom: 30px;
        }
        @include media(420px) {
            margin: {
                bottom: 0;
            }
        }
        
        &:first-child {
            padding: {
                right: 9%;
            }
            @include media(420px) {
                padding: {
                    right: 0;
                }
            }
        }

        &:last-child {
            border: {
                left: 1px solid $white;
            }
            padding: {
                left: 9%;
            }
            @include media(800px) {
                border: {
                    left: none;
                }
                padding: {
                    left: 15px;
                }
            }
            @include media(420px) {
                padding: {
                    left: 0;
                }
            }
        }
    }
    
    .widget {
        display: inline-block;
        padding: {
            top: 0;
            right: 40px;
        }
        margin: {
            bottom: 20px;
        }

        .widget-title {
            margin: {
                top: 0;
            }
            padding: {
                bottom: 6px;
            }
            color: $white;
            font-size: 14px;
            font-weight: normal;
        }

        .textwidget {
            font-size: 18px;
        }
    }
}
.site-footer {
    p,
    a {
        font: 12px/1.2 "GR";
    }

    a {
        display: block;

        &:hover {
            text-decoration: underline;
        }
    }
}


// common
.wpb_gallery {
    .wpb_image_grid_ul {
        overflow: hidden;
        height: auto !important;
    }

    .isotope-item {
        position: static !important;
        width: (100% / 4);
        padding: 10px !important;
        margin: 0 !important;
        @include media(800px) {
            width: (100% / 3);
        }
        @include media(420px) {
            width: (100% / 2);
        }
        @include media(400px) {
            width: 100%;
        }

        img {
            object-fit: cover;
            height: 270px !important;
            @include media(1024px) {
                height: 200px !important;
            }
        }
    }
}


// home
.h-about {
    background-repeat: no-repeat !important;
    background-position: right bottom !important;
    background-size: auto auto !important;
    @include media(400px) {
        > .vc_column_container > .vc_column-inner {
            padding: {
                top: 0;
            }
        }
    }

    .text {
        width: 70%;
        margin: 30px 0 !important;
        text-align: justify;
        @include media(1024px) {
            width: 90%;
            margin: {
                top: 10px !important;
            }
        }
        @include media(800px) {
            margin: {
                bottom: 20px !important;
            }
            font-size: 14px;
        }
        @include media(420px) {
            width: 100%;
            padding: {
                right: 15px;
            }
        }
    }

    .vc_btn3 {
        padding: 3px 7px;
        background-color: $primaryColor !important;
        background-image: none;
        border: none;
        border-radius: 5px;
        font-size: 12px;

        &:hover {
            border: none;
            background-color: transparent !important;
            color: $primaryColor !important;
        }
    }

    .product-category {
        margin: {
            bottom: 15px !important;
        }

        $top: 80px, 50px;
        &:first-child {
            margin: {
                top: nth($top, 1) !important;
            }
            @include media(800px) {
                margin: {
                    top: nth($top, 2) !important;
                }
            }
            @include media(420px) {
                margin: {
                    top: 0 !important;
                }
            }
        }
        &:last-child {
            margin: {
                top: -#{nth($top, 1)} !important;
            }
            @include media(800px) {
                margin: {
                    top: -#{nth($top, 2)} !important;
                }
            }
            @include media(420px) {
                margin: {
                    top: 0 !important;
                }
            }
        }

        @for $p from 1 through 4 {
            $imgD: 35px;
            $titleD: 10px;
            $r: 15px;

            &:nth-child(#{$p}) {
                @if $p == 1 {
                    background: url('../images/kitchen-bg.jpg') center center/cover no-repeat;

                    > a {
                        img {
                            right: $r;
                            bottom: $imgD;
                            max-width: 35px;
                        }

                        .woocommerce-loop-category__title {
                            right: $r;
                            bottom: $titleD;
                        }
                    }

                } @else if $p == 2 {
                    background: url('../images/livingroom-bg.jpg') center center/cover no-repeat;

                    > a {
                        img {
                            left: $r;
                            bottom: $imgD;
                            max-width: 70px;
                        }

                        .woocommerce-loop-category__title {
                            left: $r;
                            bottom: $titleD;
                        }
                    }

                } @else if $p == 3 {
                    background: url('../images/bedroom-bg.jpg') center center/cover no-repeat;

                    > a {
                        img {
                            right: $r;
                            top: $titleD;
                            max-width: 50px;
                        }

                        .woocommerce-loop-category__title {
                            right: $r;
                            top: $imgD + 10;
                        }
                    }
                    
                } @else {
                    background: url('../images/bathroom-bg.jpg') center center/cover no-repeat;

                    > a {
                        img {
                            left: $r;
                            top: $titleD;
                            max-width: 45px;
                        }

                        .woocommerce-loop-category__title {
                            left: $r;
                            top: $imgD + 18;
                        }
                    }
                    
                }
            }
        }

        @each $i in odd, even {
            &:nth-child(#{$i}) {
                > a {
                    &:hover {
                        &:after {
                            @if $i == odd {
                                transform: translateX(-100%);
                            } @else {
                                transform: translateX(100%);
                            }
                        }
                    }
                }
            }
        }

        > a {
            display: block;
            position: relative;
            padding: {
                top: 110%;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            &:after {
                background-color: rgba($color: #c5c5c5, $alpha: .8);
            }

            &:before {
                background-color: rgba($color: $black, $alpha: .7);
                @include opacity(1);
            }

            &:hover {
                &:after,
                &:before {
                    transition: opacity .3s linear;
                }
                
                &:after {
                    @include opacity(0);
                    transition: opacity .25s linear,
                    transform .3s linear;
                }

                &:before {
                    @include opacity(1);
                    transition: opacity .25s linear;
                }
            }

            img,
            .woocommerce-loop-category__title {
                position: absolute;
                z-index: 2;
            }

            img {
                margin: {
                    bottom: 0 !important;
                }
            }

            .woocommerce-loop-category__title {
                padding: 0 !important;
                text-transform: uppercase;
                font: 14px "GBold" !important;
                color: $white;

                .count {
                    @extend %none;
                }
            }
        }
    }

    .text-wrap {
        padding: {
            top: 10%;
            left: 6%;
        }
        background: url('../images/adorn.png') 80% 20% no-repeat;
        @include media(1024px) {
            padding: {
                top: 6%;
            }
            background-position: 80% 10%;
        }
        @include media(800px) {
            padding: {
                top: 3%;
            }
            background-size: 80px auto;
        }
        @include media(420px) {
            padding: {
                top: 0;
                bottom: 40px;
            }
            background-position: 80% 0;

            > .vc_column-inner {
                padding: {
                    top: 0;
                }
            }
        }
    }
}
.h-benefits {
    margin: {
        top: -255px;
    }
    padding: 20% 0 18% !important;
    background-attachment: fixed;
    @include media(800px) {
        margin: {
            top: -160px;
        }
    }
    @include media(420px) {
        margin: {
            top: -60px;
        }
        padding: {
            top: 30% !important;
        }
    }
    @include media(400px) {
        margin: {
            top: 0;
        }
    }

    .title {
        position: relative;
        @include title($white);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 30px;
            margin: {
                top: -60px;
            }
            background: url('../images/adorn2.png') center center/contain no-repeat;
        }
    }

    .note {
        margin: {
            top: 60px;
        }
        color: $white;
        @include media(800px) {
            margin: {
                top: 30px;
            }
        }

        p + p {
            margin: {
                top: 15px;
            }
            font: 14px "GItalic";
        }
    }

    .item {
        margin: {
            top: 100px;
        }
        @include media(800px) {
            margin: {
                top: 50px;
            }
        }

        .sub-title {
            margin: {
                top: 15px;
            }
            font-size: 14px;
            font-weight: normal;
        }
    }
}

// about
.a-info {
    padding: {
        bottom: 20%;
    }
    clip-path: polygon(0px 180px, 100% 28px, 100% 100%, 0px 100%);
    @include media(800px) {
        margin: {
            top: -180px;
        }
    }
    @include media(420px) {
        margin: {
            top: 0;
        }
        clip-path: none;
    }

    .wrap {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 18%;
            left: 50%;
            transform: translateX(-50%);
            width: 128px;
            height: 188px;
            background: url('../images/adorn.png') center center/contain no-repeat;
            @include media(800px) {
                top: 25%;
                left: 40%;
            }
        }
    }

    .wpb_single_image {
        max-width: 300px;
        margin: {
            left: auto;
        }
        @include media(420px) {
            margin: {
                right: auto;
            }
        }
    }

    .title {
        padding: {
            top: 45%;
        }
        @include media(800px) {
            padding: {
                top: 80%;
            }
        }
        @include media(420px) {
            padding: {
                top: 0;
                bottom: 20px;
            }
        }
    }

    .text {
        margin: {
            top: 50px;
        }
        text-align: justify;
        @include media(420px) {
            margin: {
                top: 20px;
            }
        }

        p + p {
            margin: {
                top: 16px;
            }
        }
    }
}
.a-glass {
    padding: 15% 0 20% !important;

    .title {
        @include title($white);
    }

    .note {
        margin: {
            top: 10px;
            bottom: 50px !important;
        }
        color: $white;
        @include media(420px) {
            margin: {
                bottom: 10px !important;
            }
        }
    }

    .sub-title {
        margin: {
            top: 10px;
        }
        font-size: 14px;
        font-weight: normal;
    }

    .wpb_single_image {
        margin: {
            top: 80px !important;
        }
        @include media(420px) {
            margin: {
                top: 40px !important;
            }
        }
    }
}
.a-choose {
    position: relative;
    padding: 8% 0 4% !important;
    margin: {
        bottom: -1px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: darken($primaryColor, 2%);
        clip-path: polygon(0 0, 100% 180px, 92% 100%);
        @include media(800px) {
            clip-path: polygon(0 0, 100% 100px, 92% 100%);
        }
        @include media(420px) {
            clip-path: polygon(0 0, 100% 40px, 92% 100%);
        }
    }

    .title {
        strong {
            font-size: 1.4em;
        }
    }
}
.a-reason {
    padding: 120px 0 70px !important;
    @include media(420px) {
        padding: 30px 0 40px !important;
    }

    .sub-title {
        position: relative;
        height: 50px;
        padding: {
            top: 10px;
        }
        font-size: 14px;
        font-weight: normal;
        @include media(420px) {
            display: inline-block;
            margin: {
                right: 20px;
            }
        }

        &:hover {
            color: $primaryColor !important;
        }

        + .sub-title {
            margin: {
                top: 50px;
            }
        }
    }

    .wpb_column {
        @include media(420px) {
            text-align: center;
        }
        @include media(400px) {
            padding: 0 15px;
        }

        &:first-child {
            @include media(420px) {
                padding: {
                    bottom: 30px;
                }
            }

            .sub-title + .sub-title {
                margin: {
                    top: 50px;
                }
                @include media(420px) {
                    margin: {
                        top: 25px;
                    }
                }
            } 
        }
        &:last-child {
            .sub-title + .sub-title {
                margin: {
                    top: 30px;
                }
            } 
        }


        %icon {
            content: '';
            position: absolute;
            top: 0;
            width: 50px;
            height: 50px;
        }

        &:first-child {
            .sub-title {
                padding: {
                    right: 60px;
                }

                &:after {
                    right: 0;
                    @extend %icon;
                }

                @for $i from 1 through 4 {
                    &:nth-child(#{$i}) {
                        &:after {
                            background: url('../images/rl#{$i}.png') center center/contain no-repeat;
                        }

                        &:hover {
                            &:after {
                                background-image: url('../images/rl#{$i}-#{$i}.png');
                            }
                        }
                    }
                }
            }
        }

        &:last-child {
            .sub-title {
                padding: {
                    left: 60px;
                }

                &:before {
                    left: 0;
                    @extend %icon;
                }

                @for $i from 1 through 5 {
                    &:nth-child(#{$i}) {
                        &:before {
                            background: url('../images/rr#{$i}.png') center center/contain no-repeat;
                        }

                        &:hover {
                            &:before {
                                background-image: url('../images/rr#{$i}-#{$i}.png');
                            }
                        }
                    }
                }
            }
        }
    }
}

// contact
.c-address {
    padding: 10% 0 40px !important;

    .title {
        padding: 20px 0 30px;
        font: 24px "GBold";

        + .vc_row {
            padding: {
                bottom: 20px;
            }
        }
    }

    .vc_col-sm-6 {
        &:first-child {
            padding: {
                right: 8%;
            }
            @include media(800px) {
                padding: {
                    right: 6%;
                }
            }
        }

        + .vc_col-sm-6 {
            padding: 10px 0 30px 9%;
            border: {
                left: 1px solid $white;
            }
            @include media(800px) {
                padding: {
                    left: 6%;
                }
            }
            @include media(420px) {
                border: {
                    left: 0;
                }
                padding: {
                    left: 0;
                    bottom: 10px;
                }
            }
        }

        .wpb_text_column {
            display: inline-block;
            margin: {
                right: 30px;
            }
            
            + .wpb_text_column {
                margin: {
                    top: 20px;
                }
            }
        }
    }


    .sub-title {
        font-size: 14px;
        font-weight: normal;
        color: $white;
    }

    .text {
        padding: {
            top: 10px;
        }
        font-size: 18px;
        color: $white;
    }
}
.c-form {
    padding: 20px 0 60px !important;
}
.c-map {
    padding: 0 0 40px !important;

    .title {
        padding: 20px 0;
        font: 26px "GBold";
    }
}

// guidelines
.g-plan {
    position: relative;
    padding: 15% 0 10% !important;
    background-position: center bottom !important;
    background-repeat: no-repeat !important;

    $textColor: #808080 !default;

    &:after {
        content: '';
        position: absolute;
        top: 7%;
        left: 80%;
        width: 90px;
        height: 100px;
        background: url('../images/adorn3.png') center center/contain no-repeat;
        @include media(800px) {
            top: 2%;
        }
        @include media(420px) {
            top: 2%;
            left: 70%;
            width: 70px;
            height: 60px;
        }
    }

    .title {
        @include title();
        padding: {
            right: 20px;
        }
        margin: {
            top: -16px;
        }
        @include media(800px) {
            margin: {
                top: -12px;
            }
        }
        @include media(420px) {
            text-align: left !important;
        }

        strong {
            font-size: .5em;
            font-weight: normal;
            font-family: "GBold";
        }
    }

    .text {
        color: $textColor;
        @include media(420px) {
            font-size: 14px;
        }

        p + p {
            margin: {
                top: 15px;
            }
        }

        strong {
            font-weight: normal;
            font-family: "GBold";
        }
    }

    .plan-step {
        $iconWH: 150px;
        $iconWH2: 110px;

        position: relative;
        max-width: 900px;
        margin: 40px auto 0;
        overflow: hidden;
        @include media(420px) {
            margin: {
                top: 10px;
            }
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 90%;
            height: 294px;
            border: 3px solid $primaryColor;
            border-radius: 20px;
            @include media(420px) {
                display: none;
            }
        }

        &:before {
            top: 30px + $iconWH / 2;
            right: 0;
            border: {
                left: none;
            }
        }

        &:after {
            top: 96px + $iconWH * 2;
            left: 0;
            border: {
                right: none;
            }
            border: {
                bottom-right-radius: 0;
            }
        }
        
        .item {
            width: (100% / 3);
            margin: {
                top: 30px;
            }
            text-align: center;
            @include media(420px) {
                width: (100% / 2);
                padding: 10px;
            }

            &:hover {
                .sub-title,
                .detail {
                    color: $primaryColor;
                }
            }

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    @media screen and (min-width: 421px) {
                        @if $i < 4 {
                            float: left;
        
                        } @else {
                            float: right;
                        }
                    }
                    @include media(420px) {
                        @if $i < 3 {
                            float: left;
                            
                        } @else if $i < 5 {
                            float: right;

                        } @else if $i < 7 {
                            float: left;

                        }
                    }

                    @include media(420px) {
                        
                        @if $i == 2 {
                            &:after {
                                content: '';
                                position: absolute;
                                top: $iconWH / 2 + 35;
                                right: 0;
                                width: 90%;
                                height: (100% / 3 - 5);
                                border: 3px solid $primaryColor;
                                border: {
                                    left: none;
                                    top-right-radius: 8px;
                                    bottom-right-radius: 8px;
                                }
                                @include media(400px) {
                                    top: $iconWH / 2 + 20;
                                }
                            }

                        } @else if $i == 4 {
                            &:after {
                                content: '';
                                position: absolute;
                                top: 37.8%;
                                left: 0;
                                width: 20%;
                                height: (100% / 3 - 1.5);
                                border: 3px solid $primaryColor;
                                border: {
                                    right: none;
                                    top-left-radius: 8px;
                                    bottom-left-radius: 8px;
                                }
                                @include media(400px) {
                                    top: 37%;
                                }
                            }
                        } @else if $i == 6 {
                            &:after {
                                content: '';
                                position: absolute;
                                top: 69.3%;
                                right: 0;
                                width: 90%;
                                height: (100% / 3 - 5);
                                border: 3px solid $primaryColor;
                                border: {
                                    left: none;
                                    top-right-radius: 8px;
                                    bottom-right-radius: 8px;
                                }
                                @include media(400px) {
                                    top: 68%;
                                    width: 70%;
                                }
                            }
                        }
                    }

                    .icon {
                        background: url('../images/p#{$i}.png') center center/contain no-repeat;
                    }
                }
            }
        }

        .icon {
            position: relative;
            z-index: 2;
            width: $iconWH;
            height: $iconWH;
            margin: 0 auto 10px;
            border: 3px solid $primaryColor;
            border-radius: 50%;
            @include media(400px) {
                width: $iconWH2;
                height: $iconWH2;
            }
        }

        .text-wrap {
            position: relative;
            z-index: 1;
        }

        .sub-title,
        .detail {
            font-size: 14px;
            color: $textColor;

            @include media(420px) {
                br {
                    display: none;
                }
            }
            
        }

        .sub-title {
            height: 32px;
            font-family: "GBold";
            font-weight: normal;
            @include media(400px) {
                height: auto;
            }
        }

        .detail {
            padding: {
                top: 10px;
            }
            color: $textColor;
        }

        .end {
            float: right;
            position: relative;
            z-index: 2;
            margin: {
                top: 70px;
            }
            padding: 10px 80px 10px 50px;
            background-color: $primaryColor;
            border-radius: 10px;
            font: 30px/1 "GBold";
            color: $white;
            @include media(800px) {
                margin: {
                    top: 50px;
                }
            }
            @include media(420px) {
                padding: 10px 20px;
                font-size: 24px;
            }
            @include media(400px) {
                padding: 10px;
                margin: {
                    top: 30px;
                }
                font-size: 18px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 64%;
                left: -60%;
                transform: translateY(-50%);
                width: 65px;
                height: 58px;
                background: url('../images/adorn3.png') center center/contain no-repeat;
                @include media(800px) {
                    left: -30%;
                }
                @include media(420px) {
                    display: none;
                }
            }

            span {
                display: inline-block;
                margin: {
                    right: 20px;
                }
                font: 1.7em "C";
            }
        }
    }
}

// product cat 
.p-cat {
    position: relative;
    padding: 16% 0 6% !important;
    background-position: center bottom !important;
    background-repeat: no-repeat !important;

    &:before {
        content: '';
        position: absolute;
        top: 100px;
        right: 25%;
        width: 110px;
        height: 100px;
        background: url('../images/adorn3.png') center center/contain no-repeat;
        @include media(800px) {
            top: 60px;
        }
        @include media(420px) {
            top: 20px;
            right: 10%;
            width: 80px;
            height: 70px;
        }
    }

    .title {
        @include title();
        text-transform: capitalize;
    }

    .woocommerce {
        margin: {
            top: 50px;
        }

        .product-category {
            > a {
                display: block;
                position: relative;
                padding: {
                    top: 100%;
                }
                background-color: $primaryColor;

                &:hover {
                    box-shadow: 0 20px 20px -10px rgba($primaryColor, 20%);

                    .woocommerce-loop-category__title {
                        text-shadow: 0 3px 1px $black;
                        transition: text-shadow .3s linear;
                    }
                }
            }
        }

        img,
        .woocommerce-loop-category__title {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        $imgT: 38%;
        img {
            top: $imgT;
            width: auto !important;
            margin: 0 auto !important;
        }

        .woocommerce-loop-category__title {
            top: $imgT + 30;
            font-weight: normal;
            color: $white;
            @include media(800px) {
                top: $imgT + 40;
                width: 100%;
                br {
                    display: none;
                }
            }
            @include media(400px) {
                top: $imgT + 30;
            }

            mark {
                @extend %none;
            }
        }
    }
}
.products-cat {

    .woocommerce-LoopProduct-link {
        display: block;
        position: relative;
        overflow: hidden;

        &:hover {
            &:after {
                @include opacity(0);
                transition: opacity .3s linear .2s;
            }

            .attachment-woocommerce_thumbnail {
                transform: scale(1.05);
                transition: transform .5s linear .25s;
            }

            .woocommerce-loop-product__title {
                font-size: 40px !important;
                color: rgba($white, .8);
                transition: font-size .2s linear;
                @include media(800px) {
                    font-size: 30px !important;
                }
            }
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(#414042, .8);
        }
        
        .attachment-woocommerce_thumbnail {
            margin: {
                bottom: 0 !important;
            }
        }

        .woocommerce-loop-product__title {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
            color: $white;
            font: 24px/1.2 "GBold" !important;

            > span {
                display: block;
                font-size: .6em;
            }
        }
    }

    .button {
        @extend %none;
        display: none !important;
    }
}
.product-content {
    .related,
    .tabs,
    .woocommerce-Tabs-panel > h2,
    .woocommerce-product-gallery,
    .product_meta,
    .price {
        @extend %none;
    }

    .summary {
        float: none !important;
        width: 100% !important;

        .product_title {
            @include title();
            text-align: center;
        }
    }

    .img-wrap {
        position: relative;
        height: 800px;
        text-align: center;
        @include media(800px) {
            height: 600px;
        }
        @include media(420px) {
            height: 400px;
        }
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }

    .vc_images_carousel {
        padding: 0 30px;
    }

    .wpb_images_carousel {
        margin: {
            top: 30px;
        }

        .vc_left {
            margin: {
                left: -15px;
            }
        }

        .vc_right {
            margin: {
                right: -15px;
            }
        }

        .vc_item {
            cursor: pointer !important;
        }
    }

    .productSubPic {
        margin: {
            top: 50px;
        }
    }

    .productMainPic {
        .wpb_images_carousel {
            margin: 0 auto;
            text-align: center;
        }

        .vc_item {
            text-align: center;
        }
    }

    .postRow img, 
    .productSubPic img {
        height: 100px !important;
        object-fit: cover;
    }

    .woocommerce-Tabs-panel {
        .vc_custom_heading {
            padding: {
                left: 10px;
            }
        }

        .vc_row + .vc_row {
            position: relative;
            margin: {
                top: 3em;
            }
            padding: {
                top: 3em;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: calc(100% - 50px);
                height: 1px;
                transform: translateX(-50%);
                background-color: lighten(black, 60%);
            }
        }
    }
}

// commercial
.c-commercial {
    .title {
        margin: {
            bottom: 40px;
        }
    }

    .item-list {
        text-align: center;

        .vc_col-sm-3 {
            display: inline-block;
            float: none;
        }
    }

    .item {
        @include media(420px) {
            margin: {
                top: 20px;
            }
        }

        &:hover {
            > .vc_column-inner > .wpb_wrapper {
                box-shadow: 0 20px 20px -10px #851e2d;
                transition: box-shadow .4s cubic-bezier(1, .8, .6, .2);
            }
        }

        > .vc_column-inner > .wpb_wrapper {
            padding: 30% 0;
            background-color: $primaryColor;
        }

        .sub-title {
            margin: {
                top: 20px;
            }
            font: 16px "GR", serif;

            &:hover {
                text-shadow: 0 4px 1px #000;
            }
        }
    }
}
.c-list {
    .title {
        margin: {
            bottom: 30px;
        }
    }

    .item {
        > .vc_column-inner > .wpb_wrapper {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(65,64,66,0.8);
            }

            &:hover {
                &:after {
                    @include opacity(0);
                    transition: opacity .3s cubic-bezier(1, 1, 1, 1);
                }
                
                .sub-title {
                    font-size: 40px !important;
                    color: rgba(255,255,255,0.8);
                    transition: font-size .2s cubic-bezier(1, 1, 1, 1);
                }
            }
        }

        .sub-title {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 2;
            transform: translateY(-50%);
            width: 100%;
            font: 24px "GBold" !important;
        }
    }
}
.c-content {
    .title {
        margin: {
            bottom: 30px;
        }
    }
}
.c-computer {
    .productSubPic {
        margin: {
            top: 50px;
        }
    }

    .productMainPic {
        .wpb_images_carousel {
            width: (100% / 12 * 4) !important;
            margin: 0 auto;
            @include media(420px) {
                width: 100% !important;
            }
        }
    }

    .postRow img, 
    .productSubPic img {
        height: 100px !important;
        object-fit: cover;
    }

    .vc_images_carousel {
        padding: 0 30px;
    }

    .wpb_images_carousel {
        margin: {
            top: 30px;
        }

        .vc_left {
            margin: {
                left: -15px;
            }
        }

        .vc_right {
            margin: {
                right: -15px;
            }
        }

        .vc_item {
            cursor: pointer !important;
        }
    }
}
